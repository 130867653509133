<template>
  <b-tabs
    pills

    vertical
  >
    <b-tab
      title="Title"
      active
    >
      <Title />
    </b-tab>
    <b-tab title="Steps">

      <Steps />

    </b-tab>

  </b-tabs>
</template>

<script>

import Title from './components/title.vue'
import Steps from './components/steps.vue'

export default {
  components: {
    Title,
    Steps,
  },
  data() {
    return {

    }
  },

}
</script>

<style lang="scss" scoped>
label{
  font-size: 1rem;
}
</style>
